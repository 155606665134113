@import "../../styles/global.scss";

.forgotPassword {
  width: 100%;
  height: $no_header;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 85%;
    max-width: 340px;
    margin: 1rem 0;

    p {
      text-align: center;
    }

    button {
      @extend %roundBtn;
      margin: 0.5rem 0;
    }

    .error {
      text-align: center;
      margin-top: 1rem;
      font-weight: 600;
      color: crimson;
    }
  }

  .recognisedEmail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 85%;
    max-width: 340px;

    * {
      text-align: center;
    }

    p {
      margin: 0;
      line-height: 1.5;
    }
  }
}
