@import "../../../styles/global.scss";

.strpCard {
  border: 1px solid $light_gray;
  border-radius: 20px;
  padding: 20px;

  .roundedInput {
    input {
      @extend %roundInput;
    }
  }

  h2 {
    color: $light_black;
  }
}

.expiryAndCvc {
  display: flex;
  flex-direction: row;
  width: 100%;

  .stripeElement {
    &:nth-child(1) {
      margin-right: 5px;
    }

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

}

.stripeElement {
  @extend %roundInput;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.stripeHalfwidth {
  @extend .stripeElement;
  width: 100%;
}