@import "../../../styles/global.scss";

.patientLayout {
  main {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.patient {
  padding-left: 3rem;
  padding-right: 3rem;  
  .headerArea {
    margin-bottom: 20px;

    .headerAreaContent {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid $ghost_gray;

      >div {
        margin-top: auto;
        margin-bottom: 20px;
        &:first-child {
          width: 60%;
        }
        &:last-child {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          button {
            @extend %btnTertiary;
            display: flex;
            flex-direction: row;
            margin-top: auto;
            margin-bottom: 0;
            padding: 0 0;
            height: auto;
            line-height: 100%;

            >span {
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }

        p {
          text-align: left;
          margin-bottom: 0px;
          padding-bottom: 5px;
        }
      }
    }
  }
  .content {
    margin-bottom: 20px;
    p {
      font-weight: 700;
      text-align: left;

      span {
        font-weight: 400;
      }
    }
  }
}

.patientInfoItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  > span {
    text-align: left;
    &:first-child {
      width: 200px;
      font-weight: 700;
    }
    &:last-child {
      width: 400px;
      font-weight: 400;
    }
  }
}

.bellIcon {
  fill: $dark_gray;
}

.patientOrderHistoryTable {
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    margin-left: 3rem;
  }

  >div {
    display: flex;
    flex-direction: row;
    width: 100%;

    &:nth-child(odd) {
      background-color: #FFFFFF;
    }

    &:first-of-type {
      border-bottom: 1px solid $ghost_gray;
      font-weight: 600;
      span {
        font-weight: 700;
        color: $dark_gray;
      }
    }

    >span {
      padding-bottom: 10px;
      padding-top: 10px;  
      &:nth-child(1) {
        margin-left: 3rem;
        width: 25%;
      }

      &:nth-child(2), &:nth-child(5) {
        width: 20%;
      }

      &:nth-child(3), &:nth-child(4) {
        text-align: center;
        width: 15%;
      }
    }
  }
}

.modalContent {
  h1 {
    text-align: center;
  }

  >form, >div {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    >div {
      margin-bottom: 10px;
    }
  }

  >div {
    >p {
      margin-bottom: 30px;
    }
  }
}