@import "../../styles/global.scss";

.clinicalTestWarning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  h3 {
    text-align: center;
  }
}

.redirect {
  @extend .btnSecondary;
  margin-top: 10px;
}

.continue {
  @extend .btnTertiary;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.warningBanner {
  position: sticky;
  top: 0;
  z-index: 1;
  background: $alert_red;
  height: 50px;
  color: $light_black;
  display: flex;
  justify-content: center;
  justify-items: center;
  >div {
    display: flex;
    flex-direction: row;
    .warningSymbol {
      height: 50px;
      width: fit-content;
      margin-right: 20px;
      font-size: 40px;
    }
    .textContent {
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      width: fit-content;
    }
  }
}