.skuDetailsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding-left: 4px;
  padding-bottom: 4px;
}

.labelColumn,
.valueColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.5rem;
  background-color: white;
}

.labelColumn {
  div {
    color: hsl(0, 0%, 50%);
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
  }
}

.valueColumn {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  div {
    color: #333;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
  }
}

.skuDetailsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0px;

  th, td {
    padding: 12px;
    text-align: left;
    vertical-align: top;
    font-size: 0.875rem;
    font-family: 'Inter', sans-serif;
    line-height: 1.4;
  }

  th {
    width: 20%;
    color: hsl(0, 0%, 50%);
    font-weight: 600;
    border-radius: 8px 0 0 8px;
  }

  td {
    width: 80%;
    color: #333;
    font-weight: 500;
    border-radius: 0 8px 8px 0;
  }

  tr:hover {
    th, td {
      background-color: #f0f0f0;
    }
    th {
        color: hsl(0, 0%, 30%);
    }
    td {
        color: hsl(0, 0%, 0%);
    }
  }
}
