@import "../../../styles/global.scss";

.dialogContent {
  @extend %dialogContent;
}

%dialogContent {
  display: flex;
  flex-direction: column;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
}