.button {
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.button--primary {
    background-color: #007bd0;
    color: white;

    &:hover:not(:disabled) {
      background-color: #5ba4d7;
    }
  }

  &.button--secondary {
    background-color: #e7ecf3;
    color: black;

    &:hover:not(:disabled) {
      background-color: #007bd0;
      color: white;
    }
  }

  &.button--ghost {
    background-color: transparent;
    color: black;

    &:hover:not(:disabled) {
      background-color: #E6E6E6;
    }
  }

  &.button--outline {
    background-color: transparent;
    border: 1px solid #e4e4e7;
    color: black;

    &:hover:not(:disabled) {
      background-color: #f4f4fa;
    }
  }

  &.button--disabled {
    border: 1px solid #dfdedd;
    background-color: #fbfaf9;
    color: #b3b3b3;
    cursor: not-allowed;
  }

  &.button--loading {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #5ba4d7;
    color: white
  }
  &.button--default {
    padding: 8px 16px;
  }

  &.button--icon {
    padding: 10px;
  }

  .loader {
    width: 14px;
    height: 14px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top: 2px solid transparent;
    animation: spin 1s linear infinite;
    flex-shrink: 0;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}