@import "../../../styles/global.scss";


.Modal {
    position: absolute;
    display: flex;
    top: 25%;
    left: 25%;
    right: 25%;
    bottom: 25%;
    background-color: white;
    justify-content: center;
    margin: auto;
    border: 0px solid black;
    border-radius: 15px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    &:focus {
        outline: none;
    }
}