@import "../../../../styles/global.scss";

.addPatientBtn {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: none;
  color: $fm_blue;

  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
  }

  span {
    font-weight: 500;
    overflow: hidden;
    color: $fm_blue;
    margin-left: 0.5rem;
  }

  &:hover span {
    animation: pulse 0.6s;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      //text-decoration: underline;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus img {
    border: 2px solid $fm_blue;
    padding: 0.2rem;
    border-radius: 50%;
  }
}