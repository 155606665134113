@import "../../styles/global.scss";

nav {
  box-sizing: border-box;
  width: $sidebar_width;
  min-height: $no_header;
  -webkit-box-shadow: 14px 15px 35px -23px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 14px 15px 35px -23px rgba(0, 0, 0, 0.25);
  box-shadow: 14px 15px 35px -23px rgba(0, 0, 0, 0.25);
  background: linear-gradient(315deg, #76c3f8 0%, #007bd0 100%);
}

.mainMenu {
  display: block;
  font-weight: 500;
  color: white;
  padding-inline-start: 0;
  font-size: 16px;
  transform: translateY(2rem);
  color: white;
  position: sticky;
  top: 0;

  li {
    position: relative;
    cursor: pointer;
    margin-bottom: 1.5em;
    text-align: left;
    color: white;
  }

  div {
    display: none;
    position: absolute;
    left: 0;
    height: 1.2em;
    width: 0.4em;
    background-color: white;
  }
}

.activeNav {
  opacity: 1;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    width: 7px;
    height: 1.2rem;
    background: white;
  }
}

.passiveNav {
  opacity: 0.5;
}

.active-square {
  display: block;
}

.bottomMenu {
  @extend .mainMenu;

  position: fixed ;
  bottom: 1rem;
  top: auto;
}