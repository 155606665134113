.dropdown {
  position: relative;
  flex: 1;

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    max-height: 200px;
    overflow-y: auto; 
    background-color: white;
    border: 1px solid #e4e4e7;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px;
    margin-top: 4px;
    z-index: 10;

    &--bottom-right {
      left: auto;
      right: 0;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    color: #6B7280;
    padding: 6px 10px;
    border-bottom: 1px solid #e4e4e7;
    margin-bottom: 4px;
  }

  &__item {
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #f4f4f5;
    }

    &--default {
      padding: 10px 14px;
    }

    &--small {
      font-size: 12px;
      padding: 6px 10px;
    }

  }
}