@import "../../styles/global.scss";

.loadingIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  img {
    @extend %loadingIconDimensions;
    @extend %rotate;
  }
}