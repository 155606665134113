@import "../../../../styles/global.scss";

$v_padding: 20px;

.PercentageInput {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  > label {
    padding-top: $v_padding + 2;
    padding-bottom: $v_padding - 2;

    &::after {
      content: ":";
    }
  }

  .input {
    display: flex;
    flex-direction: row;
    justify-content: right;

    > input {
      width: 100%;
      padding-top: $v_padding;
      padding-bottom: $v_padding;
      border-radius:20px;
      border: 0;
      -webkit-box-shadow:inset 0 0 0 1px #ccc;
      box-shadow:inset 0 0 0 1px #ccc;
      max-width: 80px;
      text-align: right;
      padding-right: 10px;
      &:focus {
        -webkit-appearance:none;
        outline:none;
        -webkit-box-shadow:inset 0 0 0 2px #007bff;
                box-shadow:inset 0 0 0 2px #007bff;
      }
    }

    >span {
      padding-top: $v_padding;
      padding-bottom: $v_padding;  
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;
      width: 20px;
    }
  }
}