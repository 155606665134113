@import "../../../styles/global.scss";
@import "../../dialogs/Dialog/Dialog.module.scss";

.errorDialogContent {
    @extend %dialogContent;

    h2 {
        color: red;
    }
}
