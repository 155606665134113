@import "../../DashAdmin.module.scss";

.dashAdmin {
  @extend .dashAdmin;
  >div {
    justify-content: center;
    align-items: center;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .shippingAndInputs {
    display: flex;
    flex-direction: row;
    >div {
      margin-right: 10px;
      margin-left: 10px;
      &:first-child {
        height: fit-content;
        width: 400px;
        overflow: scroll;
      }
      &:last-child {
        width: 400px;
      }
    }
    .inputs {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.submitButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 20px;
  button {
    @extend .btnPrimary;
  }
}

.orderRequestSummary {
  tr {
    td {
      &:first-child::after {
        content: ":";
      }
      &:not(:first-child) {
        justify-content: right;
        text-align: right;
        justify-items: right;
      }
    }
    &:last-child {
      td {
        padding-top: 10px;
        font-weight: 500;
      }
    }
  }
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid $ghost_gray;
  border-radius: 20px;
}

.shippingDetailsSummary {
  @extend .box;
  overflow: scroll;
  table {
    width: 100%;
    tr {
      &:not(:last-child) {
        td {
          &:first-child::after {
            content: ":";
          }
        }
      }
      td {
        &:not(:first-child) {
          justify-content: right;
          text-align: right;
          justify-items: right;
        }
      }
      &:last-child {
        td {
          text-align: left;
          font-size: small;
        }
      }
    }
  }
}

.sideBySideInputs {
  display: flex;
  flex-direction: row;
}

.orderStatus {
  input[type="checkbox"] {
    margin-right: 5px;
    margin-bottom: 10px;
  }
}

.box {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid $ghost_gray;
  border-radius: 20px;
  color: $light_black;
  h4 {
    text-align: center;
    color: $light_black;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.orderRequestItemsTable {
  @extend .box;
  table {
    thead, tbody {
      tr {
        th,td {
          &:nth-child(-n+2) {
            text-align: left;
            padding-right: 10px;
          }
          &:nth-child(n+3) {
            text-align: right;
            padding-left: 10px;
          }
        }
      }
    }
    thead {
      tr {
        &:last-child {
          th {
            padding-top: 10px;
          }
        }
      }
    }
    tbody {
      td {
        &:nth-child(n+2) {
          @extend .tabularNum;
        }
      }
    }
    tfoot {
      td {
        text-align: right;
        font-weight: 600;
      }
    }
  }
}