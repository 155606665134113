@import "../../styles/global.scss";

.register {
  max-width: 720px;
  padding: 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  
  div {
    div {
      margin-bottom: 0;
    }
  }

  button {
    @extend %roundBtn;
    width: 50%;
    margin: auto;
  }

  .intro {
    margin-bottom: 20px;

    h1 {
      text-align: center;
      margin-bottom: 3rem;
    }

    p {
      line-height: 1.5rem;
      text-align: center;
    }
  }

  .inputs {
    >div {
      margin-bottom: 2px;
    }

    button {
      margin-top: 10px;
    }
  }

  .contractWrapper {
    margin: 2rem auto;
    height: 400px;
    padding: 1.5rem;
    overflow-y: scroll;
    border: 1px solid #a9a9a9;
    border-top-left-radius: 2rem;
  }
}