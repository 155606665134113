@import "../../../styles/global.scss";

.challengesLayout {
  main {
    padding-left: 0;
    padding-right: 0;
  }
}

.desc {
  padding-left: 3rem;
  padding-right: 3rem;

  p {
    text-align: left;
  }
}

.challengesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  .challengeList {
    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .challengeCard {
      width: 100%;
      border-top: 1px solid transparent;
      border-bottom: 1px solid $ghost_gray;
      transition: all 200ms;

      &:not(.chalHeaderCard) {
        &:hover {
          border-top: 1px solid $fm_blue;
          border-bottom: 1px solid $fm_blue;
        }
        &:not(.reviewed) {
          background: white;
        }
      }

      .chalInfo {
        cursor: pointer;
      }

      .chalInfo,
      .chalHeader {
        padding: 1rem 2rem;
        display: flex;
        align-items: center;

        span {
          display: flex;
          font-weight: 700;
          color: $light_black;
          overflow-x: hidden;
        }
      }

      .chalInfo, .chalHeader {
        span {
          // Checkbox
          &:first-of-type {
            width: 3%;
          }
  
          // Name
          &:nth-of-type(2) {
            width: 22%;

            &.chalLabelCell {
              display: inline;
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }
  
          // Date
          &:nth-of-type(3) {
            @extend .tabularNum;
            width: 18%;
          }
          
          // Result indicator
          &:last-of-type {
            width: 9%;
            padding-left: 10px;
            justify-content: center;
          }
        }
      }

      .reviewStatus {
        height: 23px;
        fill: $dark_gray;
        stroke: $dark_gray;
        stroke-width: 0;
      }

      .chalHeader {
        span {
          min-height: 20px;
          align-items: center;
          // blank
          &:nth-of-type(4) {
            width: 6%;
          }

          // baseline
          &:nth-of-type(5) {
            width: 9%;
            justify-content: center;
          }

          // AUC
          &:nth-of-type(6) {
            width: 21%;
            justify-content: center;
          }

          // Result
          &:nth-of-type(7) {
            width: 9%;
            justify-content: center;
          }
        }

        img {
          margin-left: 10px;
        }
      }

      .chalInfo {
        span {
          // Date
          &:nth-child(2) {
            color: $dark_gray;
          }

          //Dose ... Result
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            font-weight: 500;
            color: $dark_gray;
          }
        }

        // &:hover {
        //   border-top: 1px solid $fm_blue;
        //   border-bottom: 1px solid $fm_blue;
        // }
      }

      .chalHeader {
        border-top: 1px solid $ghost_gray;
        // border-bottom: 1px solid $ghost_gray;
      }

      .chalCardResultTable {
        display: flex;
        flex-direction: column;
        width: 45%;
      
        div {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $ghost_gray;

          &:last-child {
            border-bottom: none;
          }
        }
      
        span {
          &:nth-of-type(1) {
            font-weight: 500;
            color: $light_gray;
            width: 13.33%;
          }

          &:nth-of-type(2) {
            width: 20%;
          }

          &:nth-of-type(3) {
            width: 46.66%;
          }

          &:nth-of-type(4) {
            font-weight: 500;
            width: 20%;

            &.positive {
              >span {
                color: $alert_red;
              }
            }

            &.negative {
              >span {
                color: $success_green;
              }
            }
          }
        }

        .numeric {
          display: flex;
          flex-direction: row;


          span {
            @extend .tabularNum;
            color: $dark_gray;
            font-weight: 600;

            &:nth-of-type(1) {
              width: 40%;
              justify-content: right;
              text-align: right;
            }

            &:nth-of-type(2) {
              width: 60%;
              text-align: right;
            }
          }
        }
      }
    }

    #openChallengeStrip {
      font-weight: 800;
    }

    #challengeCanvasWrapper {
      display: block;
      width: 900px;
      height: 400px;
      margin: 2rem auto;
    }
  }
}

.highBaseline {
  //Positioning of HB expandable strip
  display: inline;
  margin-left: 0.8rem;
  
  //Shape and color of strip
  background-color: $alert_red;
  border-radius: 10px;
  padding: 5px 8px;

  //Related to High Baseline text
  overflow: hidden;
  white-space: nowrap;

  //Text/font
  color: white;
  font-weight: 800;
  font-size: 9px;

  //Transition animation
  //NOTE: transitions do not work well with 'height:auto' thus, we apply transitions on max/min-width parameters. Both transitions appear to be needed,
  //max-width is set to be wider than the auto width required to display 'HB' and 'High Baseline'
  //similarly min-width is set to be smaller than the auto width's 'HB' and 'High Baseline'
  //widths required are ~ 30 px and 81 px respectively

  max-width: 34px;
  min-width: 26px;
  transition: max-width 0.5s, min-width 0.5s;

  &:hover {
    // transform: translateY(-10px);
    position: absolute;  
    max-width: 86px;
    min-width: 76px;
  }
}

.resultsMarker {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
}

.poorlyDigested {
  @extend .resultsMarker;
  background-color: $alert_red;
}
.adequatelyDigested {
  @extend .resultsMarker;
  background-color: $success_green;
}

.stickyContainer {
  @extend %stickyHeader;
}

.loadingIcon {
  @extend %rotate;
  @extend %loadingIconDimensions;
}


.pdfDownloadButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem;
    margin-left: auto;
    margin-bottom: 10px;

    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
}

.modalContent {
  h2 {
    text-align: center;
  }
  button {
    margin: auto;
    margin-top: 30px;
  }
}

.editNotesModalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    textarea {
      border-radius: 10px;
      width: 60%;
      height: 200px;
      margin-bottom: 20px;
    }
  }
}