.switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s;
    border: 0;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    
    &:focus {
      outline: none;
    }
  
    &.default {
      min-width: 44px;
      height: 22px;
      line-height: 22px;
  
      .handle {
        width: 18px;
        height: 18px;
        top: 2px;
        left: 2px;
      }
  
      &.checked .handle {
        left: calc(100% - 20px);
      }
    }
  
    &.small {
      min-width: 28px;
      height: 16px;
      line-height: 16px;
  
      .handle {
        width: 12px;
        height: 12px;
        top: 2px;
        left: 2px;
      }
  
      &.checked .handle {
        left: calc(100% - 14px);
      }
    }
  
    &.checked {
      background-color: #1890ff;
    }
  
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  
    .handle {
      position: absolute;
      transition: all 0.2s ease-in-out;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    }
  }