.container {
  max-width: 42rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  display: flex;
  gap: 20px;

  > div {
    flex: 1;
  }
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.preferenceValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  font-size: 12px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background-color: #f4f4f5;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 14px;
  color: black;
  cursor: default;
}

.checkboxWrapper {
  margin-bottom: 20px;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.checkboxLabel {
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}

.disabledText {
  color: #71717a;
  font-size: 12px;
  margin-left: 4px;
}

.disabledMessage {
  display: flex;
  justify-content: center;
}

.messageCard {
  background-color: #fafafa;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  padding: 0 20px;
  width: 100%;

  p {
    color: #52525b;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
  }
}

// Summary variant styles
.summaryContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.summaryHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
}

.summaryContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.summaryRow {
  display: flex;
  gap: 1rem;
  width: 100%;

  > * {
    flex: 1;
  }
}

.summaryHalf {
  flex: 1;
  min-width: 0;
}

.summaryInputGroup {
  display: flex;
  gap: 1rem;

  > * {
    flex: 1;
    min-width: 0;
  }
}
