@import "../../../styles/global.scss";

.nav {
    button {
      position: absolute;
      top: 2rem;
      background: white;
      color: $fm_blue;
      font-weight: 800;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $fm_blue;
      border-radius: 50%;
      transition: all 400ms;
      right: 2rem;
      font-size: 0.8rem;
      z-index: 1;

      &:hover {
        background: $fm_blue;
        color: white;
      }
    }
  }