@import "../../../styles/global.scss";

// Copies most styling from Challenges.module.scss
// A couple of significant changes to functionality
// Styling is not inherited from Challenges.module.scss to avoid changes to main dash Challenges page causing unpredictible changes to mini page

.challengesContainer {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  .loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .challengeList {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .chalHeaderCard {
      margin-top: 0;
      padding-top: 0;
    }

    .challengeCard {
      width: 100%;
      border-top: 1px solid transparent;
      border-bottom: 1px solid $ghost_gray;
      transition: all 200ms;
      &:not(.chalHeaderCard) {
        &:hover {
          border-top: 1px solid transparent;
          border-bottom: 1px solid $ghost_gray;
        }
        &:not(.reviewed) {
          background: white;
        }
      }

      .chalInfo {
        cursor: default;
      }

      .chalInfo,
      .chalHeader {
        width: 100%;

        padding: 1rem 2rem;
        display: flex;
        align-items: center;

        span {
          display: flex;
          font-weight: 700;
          color: $light_black;
          overflow-x: hidden;
        }

        > span {
          &:nth-child(1) {
            width: 5%;
            justify-content: center;
          }
          &:nth-child(2) {
            width: 20%;
            padding-left: 0;
          }
          &:nth-child(8) {
            padding: 0;
            width: 5%;
            justify-content: center;
          }
        }
      }
      .chalHeader {
        border-top: none;
        > span {
          min-height: 20px;
          align-items: center;
          &:nth-child(3) {
            width: 7%;
          }
          &:nth-child(4) {
            width: 7%;
            justify-content: right;
          }
          &:nth-child(5) {
            width: 21%;
            justify-content: center;
          }
          &:nth-child(6) {
            width: 10.5%;
            justify-content: center;
          }
          &:nth-child(7) {
            width: 24.5%;
            justify-content: center;
          }
        }
      }

      .chalCardResultTable {
        display: flex;
        flex-direction: column;
        width: 70%;

        div {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $ghost_gray;

          &:last-child {
            border-bottom: none;
          }
        }

        > div {
          > span {
            &:nth-child(1) {
              font-weight: 500;
              color: $light_gray;
              width: 10%;
            }

            &:nth-child(2) {
              width: 10%;
            }

            &:nth-child(3) {
              width: 30%;
            }

            &:nth-child(4) {
              width: 15%;
              font-weight: 500;
            }

            &:nth-child(5) {
              width: 35%;
              font-weight: 500;
              justify-content: center;
            }

            &.positive {
              color: $alert_red;
            }

            &.negative {
              color: $success_green;
            }
          }
        }

        span {
          &.positive {
            color: $alert_red;
          }

          &.negative {
            color: $success_green;
          }
        }

        .positive {
          span {
            color: $alert_red;
          }
        }

        .negative {
          span {
            color: $success_green;
          }
        }

        .numeric {
          display: flex;
          flex-direction: row;

          &:not(.positive):not(.negative) {
            span {
              color: $dark_gray;
            }
          }

          span {
            @extend .tabularNum;
            font-weight: 600;

            &:nth-of-type(1) {
              width: 47.5%;
              justify-content: right;
              padding-right: 10px;
            }

            &:nth-of-type(2) {
              width: 52.5%;
              justify-content: left;
            }
          }
        }
      }
    }
  }
}

.directionButton {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 20px;
  margin: 0 0.5rem;
  color: $light_gray;
  display: inline-block;

  &:hover {
    color: gray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
}

.infoWidget {
  width: 100px;
}

#challengeCanvasWrapper {
  display: block;
  width: 900px;
  height: 400px;
  margin: 2rem auto;
}

.dataTable {
  margin-top: 2rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center; 

  table {
    width: auto;
    border-collapse: collapse;
    table-layout: auto;
    margin-bottom: 1rem;

    th, td {
      padding: 0.5rem 1rem;
      text-align: left;
      border-bottom: 1px solid $ghost_gray;
      white-space: nowrap;
    }

    th:first-child,
    td:first-child {
      padding-right: 2rem;
    }

    th {
      background-color: $clinical_green;
      color: white;
      font-weight: bold;
    }

    .positiveCell {
      background-color: rgba($alert_red, 0.2);
    }

    .negativeCell {
      background-color: rgba($success_green, 0.2);
    }
  }

  .dataTableDesc {
    margin-bottom: 1rem;
    font-size: 0.8rem;
    color: $light_gray;
    text-align: center;
    align-self: center;
  }
}
