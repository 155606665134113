.buttonGroup {
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.header {
  display: flex;
  align-items: center;

  h1 {
    margin-left: 10px;
  }
}