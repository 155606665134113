@import "../IFTAInput/IFTAInput.module.scss";
@import "../ifta-shared.module.scss";

.IFTAPhoneInput {
  width: 100%;

  --PhoneInputCountrySelectArrow-opacity: 0;

  &:focus-within {
    label {
      color: $fm_blue;
    }
  }

  div {
    width: 100%;
    div {
      width: fit-content;
      div {
        width: max-content;
        max-width: 25px;
        margin-bottom: 20px;
      }
    }
    &:first-child {
      input {
        @extend %iftaField;
        width: 100%;
        padding: 45px 20px 20px;
        height: 3em;
      }
    }
  }

  label {
    @extend %iftaLabel;
    padding:0px 10px 0 56px;
    top: -70px;
    
    &::first-letter {
      text-transform: uppercase;
    }
  }
}