@import "../../styles/global.scss";

.header {
  position: relative;
  height: 90px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;

  >div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    margin-right: 20px;
    margin-left: 20px;

    @media (min-width:480px) {
      >span {
        &:first-child,&:last-child {
          width: 400px;
        }

        &:nth-child(2) {
          display: flex;
          justify-content: center;
        }
      }
    }

    @media (max-width:480px) {
      >span {
        .languageSelect {
          >div {
            >label {
              display: none;
            }
          }
        } 
      }
    }
  }
}

.logo {
  cursor: pointer;
  max-height: $header_height - 1px; /* minus border */
  @media (min-width:480px) {
    padding: 10px;
  }
}

.logoutBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;

  button {
    @extend %roundBtn;
    height: 2.2rem;
    width: 200px;
    max-width: 200px;
    line-height: 2.2rem;
    min-width: fit-content;
  }
}

.dashAdminButton {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  cursor: pointer;
  margin-top: 30px;
  padding-left: 10px;

  button { 
    @extend %roundBtn;
    height: 2.2rem;
    line-height: 2.2rem;
    background-color: $light_black;
  }
}

.languageSelect {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: right;
  align-items: right;

  div {    
    display: flex;
    flex-direction: row;
    select {
      font-size: 30px;
      border: none;
      cursor: pointer;
    }
    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 20px;
      padding-bottom: 5px;
      padding-right: 10px;
      text-transform: capitalize;
    }
  }
}