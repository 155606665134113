@import "./_variables/variables.module";

// This style was accidentally made global when it wasn't contained in a class when initially added to DigestHeader.module.scss
// Do not remove unless you want to refactor a lot of <div><button>...</button></div> component styles
div {
  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;

    span {
      font-size: 1rem;
      margin-left: 0.2rem;
      transition: all 0.4s;
    }

    &:hover span {
      margin-left: 0.4rem;
      color: $fm_blue;
    }
  }
}


%roundBtn {
  cursor: pointer;
  font-size: 0.9em;
  display: block;
  padding: 0 2em;
  height: 3em;
  line-height: 3em;
  border-radius: 10vw;
  border: 0;
  color: #ffffff;
  background-color: $fm_blue;
  transition: 400ms all;
  text-transform: capitalize;

  &:hover {
    background-color: #005da0;
  }

  &:disabled {
    background-color: #007bd033;
  }
}

%btn {
  cursor: pointer;
  font-size: 0.9em;
  display: block;
  padding: 0 2em;
  height: 3em;
  line-height: 3em;
  border-radius: 5vw;
  transition: 400ms all;
  font-weight: 550;
}

.btnPrimary {
  @extend %btnPrimary;
}

.btnSecondary {
  @extend %btnSecondary;
}

.btnTertiary {
  @extend %btnTertiary;
}

%btnPrimary {
  @extend %btn;

  color: #ffffff;
  background-color: $fm_blue;
  border: 0;
  text-transform: capitalize;

  &:hover {
    background-color: #005da0;
  }

  &:disabled {
    background-color: #007bd033;
  }
}

%btnSecondary {
  @extend %btn;

  color: $dark_gray;
  border: 2px solid $ghost_gray;

  &:hover {
    border-color: $fm_blue;
    color: $fm_blue;
  }

  &:disabled {
    color: $ghost_gray;
    border-color: $ghost_gray;
  }
}

%btnTertiary {
  @extend %btn;

  color: $dark_gray;
  svg {
    fill: $dark_gray;
  }

  &:hover {
    color: $fm_blue;
    svg {
      fill: $fm_blue;
      transition: 400ms all;
    }
  }

  &:disabled {
    color: $ghost_gray;
  }
}

%roundInput {
  box-sizing: border-box;
  display: inline-block;
  font-size: 0.9em;
  min-width: 9vw;
  height: 3em;
  border-radius: 10vw;
  border: 1px solid #d9d9d9;
  padding: 0 25px;
  color: black;

  &::placeholder {
    color: #d9d9d9;
  }
}

%explanation {
  line-height: 1.8rem;
}

%loadingIconDimensions {
  height: 30px;
  width: 30px;
}

%rotate {
  animation: rotating 2s linear infinite;
}

.globalLoadingSpinner {
  @extend %loadingIconDimensions;
  @extend %rotate;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

%stickyHeader {
  background: $bg_gray;
  padding-top: 2rem;
  position: sticky;
  top: 0;
}

%btnGroup {
  display: flex;
  flex-direction: row;
  margin: auto;
}

%btnGroupOffsetRight {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-right: 0;
  margin-left: auto;
}

.errP {
  color: $err_red;
  text-align: center;
  margin-top: 0;
  padding-top: 0;
}

.errP::before {
  display: inline;
  content: "⚠ ";
}

.formError {
  @extend .errP;
}

.modal {
  position: absolute;
  left: 25%;
  top: 15%;
  border: 1px solid black;
  background: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  padding: 4rem 2rem 3rem 2rem;

  h1 {
    top: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  p {
    text-align: justify-center;
    margin: 2rem 0;
    line-height: 1.5rem;
  }
}

.displayNone {
  display: none;
}

.tabularNum {
  text-align: right;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

// functions to urlencode an svg string

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function url-encode($string) {
  $map: (
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    " ": "%20",
    "!": "%21",
    "*": "%2A",
    "'": "%27",
    '"': "%22",
    "(": "%28",
    ")": "%29",
    ";": "%3B",
    ":": "%3A",
    "@": "%40",
    "&": "%26",
    "=": "%3D",
    "+": "%2B",
    "$": "%24",
    ",": "%2C",
    "/": "%2F",
    "?": "%3F",
    "#": "%23",
    "[": "%5B",
    "]": "%5D"
  );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

@function inline-svg($string) {
  @return url('data:image/svg+xml;utf8,#{url-encode($string)}');
}

@function svg-string-with-color($string,$colorString) {
  $index: str-index($string,'<g');
  @if $index {
    @return str-insert($string, ' fill="' + $colorString + '" ',$index+2);
  }
  @return $string;
}