@import "../../styles/global.scss";

// The following overrules the default Flatpickr styling
.datePicker {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  .dates {
    input {
      cursor: pointer;
      display: inline-block;
      font-family: "Montserrat";
      font-size: 0.9rem;
      width: 14rem;
      padding: 0.1rem 1rem;
      background: transparent;
      border: 1px solid $light_gray;
      border-radius: 1rem;
      font-weight: 500;
      color: #6a6a6a;
      background-color: $bg_gray;
      text-align: center;

      &:hover {
        border: 1px solid #4a4a4a;
      }

      &:focus {
        outline: none;
        border: 1px solid #007bd0;
        border-radius: 1rem;
      }
    }

    button {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 1rem;
      transform: scale(2.5) translateY(-0.05rem);
      margin: 0 0.5rem;
      color: $light_gray;

      display:inline-block;

      &:hover {
        color: gray;
      }

      &:disabled {
        cursor: default;
        opacity: 0.1;
      }
    }
  }

  .periods {
    li {
      cursor: pointer;
      margin: 0 0.5rem;
      display: inline-block;
      font-weight: 700;
      font-size: 1em;
      color: $light_gray;
      padding: 0;
      text-transform: capitalize;

      &:hover {
        color: gray;
      }
    }
  }

  .active {
    color: $light_black !important;
    border-bottom: solid 3px $light_black;
  }
}
