@import "../DashAdmin.module.scss";

.dashAdmin {
  @extend .dashAdmin;

  form {
    .labelledCheckbox {
      display: flex;
      justify-content: center;
    }
  }
}

.affiliateDetails {
  border: 1px solid $ghost_gray;
  border-radius: 20px;
  padding: 20px;

  h4 {
    color: $dark_gray;
    margin-top: 0;
    text-align: center;
  }

  .inputs {
    margin: auto;
    width: 280px;

    >div {
      margin-top: 5px;
    }
  }
}