@import "../../styles/global.scss";

.salesLayout {
  main {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.top {
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .desc {
    width: 50%;
    p {
      text-align: left;
    }
  }
}

.summaryTableContainer {
  width: 40%;
  .salesSummaryTable {
    display: flex;
    flex-direction: column;
    border: 1px solid $ghost_gray;
    border-radius: 10px;
    width: 100%;
    background-color: #FFFFFF;

    >div {
      display: flex;
      flex-direction: row;

      &:first-child {
        border-bottom: 1px solid $ghost_gray;
        font-weight: 600;
        font-size: 12px;
      }

      span {
        text-align: right;
        color: $light_gray;
        &:nth-child(1), &:nth-child(2),&:nth-child(4) {
          width: 23%;  
        }
        &:nth-child(3) {
          width: 31%;
        }
        &:not(:first-child) {
          border-left: 1px solid $ghost_gray;
        }
        &:first-child, &:last-child {
          color: $dark_gray;
        }
        &:first-child {
          font-weight: 600;
          font-size: 12px;
          text-align: left;
          padding-left: 20px;
        }

        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
      }


    }
  }
}

.salesOverviewTable {
  $firstWidth: 13%;
  $otherWidth: 14.5%;

  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      &:first-child {
        margin-left: 3rem;
        width: $firstWidth;
      }
    }

    &:not(:first-child) {
      >span {
        &:not(:first-child) {
          width: $otherWidth;
        }
      }
    }
  }

  .header {
    border-top: 1px solid $ghost_gray;
    border-bottom: 1px solid $ghost_gray;
    span {
      margin-top: 5px;
      margin-bottom: 5px;

      &:first-child {
        width: $firstWidth;
      }
      &:not(:first-child) {
        width: $otherWidth * 2;
        border-left: 1px solid $ghost_gray;
      }
    }
  }

  .header, .subheader {
    span {
      color: $dark_gray;
      font-weight: 600;
      text-align: center;
    }
  }

  .subheader {
    margin-top: 10px;
    span {
      &:first-child {
        text-align: left;
      }
    }
  }

  >div:not(.header) {
    &:nth-child(odd) {
      background-color: #FFFFFF;
    }
    span {
      padding-top: 15px;
      padding-bottom: 15px;
  
      &:first-child {
        text-align: left;
      }
      &:not(:first-child) {
        text-align: center;
      }
      &:nth-child(even) {
        border-left: 1px solid $ghost_gray;
      }
    }
  }
}

.salesDetailedOverviewTable {
  display: flex;
  flex-direction: column;

  >div {
    display: flex;
    flex-direction: row;
    padding: 15px 0;

    &:nth-child(even) {
      background-color: #FFFFFF;
    }

    &:first-child {
      span {
        font-weight: 600;
        color: $dark_gray;
      }
    }

    &:not(:first-child) {
      span {
        &:nth-child(3),&:nth-child(4),&:nth-child(5) {
          @extend %numeric;
        }
      }
    }

    >span {
      &:nth-child(1) {
        width: 20%;
        margin-left: 3rem;
      }
      &:nth-child(2) {
        width: 13%;
      }
      &:nth-child(3),&:nth-child(4),&:nth-child(5) {
        width: 13%;
        text-align: right;
        padding-right: 3rem;
      }
      &:nth-child(6) {
        width: 20%;
        padding-left: 3rem;
      }
    }
  }
}

%numeric {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}