// widths
$sidebar_width: 15vw;
$no_sidebar: calc(100vw - 15vw);

// heights
$header_height: 90px;
$footer_height: 80px;
$no_header: calc(100vh - 90px);
$no_header_no_footer: calc(100vh - 90px - 80px);

// colors
$clinical_green: #0e585f;
$fm_blue: #007bd0;
$light_blue: #469fe0;
$dark_blue: #005da0;
$bg_gray: #f5f6fa;
$darker_bg_gray: #e6e6e6;
$ghost_gray: #d1d1d1;
$light_gray: #a9a9a9;
$dark_gray: #707070;
$light_black: #333;
$success_green: #7bb35a;
$alert_red: #ff392e;
$err_red: #bf1650;

:export {
  sidebarWidth: $sidebar_width;
  noSidebar: $no_sidebar;
  headerHeight: $header_height;
  footerHeight: $footer_height;
  noHeader: $no_header;
  noHeaderNoFooter: $no_header_no_footer;
  clinicalGreen: $clinical_green;
  fmBlue: $fm_blue;
  lightBlue: $light_blue;
  darkBlue: $dark_blue;
  bgGray: $bg_gray;
  darkerBgGray: $darker_bg_gray;
  ghostGray: $ghost_gray;
  lightGray: $light_gray;
  darkGray: $dark_gray;
  lightBlack: $light_black;
  successGreen: $success_green;
  alertRed: $alert_red;
  errRed: $err_red;
}