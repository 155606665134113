@import "../../../../styles/global.scss";
@import "../ifta-shared.module.scss";

.IFTAInput {
  width:100%;

  label {
    @extend %iftaLabel;
  }
  
  input {
    @extend %iftaField;
    height: 3em;
  }

  input:focus + label {
    @extend %iftaFocusLabel;
  }
}