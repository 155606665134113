@import "../../../styles/global.scss";

$small: 728px;

.gettingStarted {
  max-width: 720px;
  padding: 2rem;
  margin: 0 auto;

  ul {
    li {
      list-style-type: disc;
      padding-bottom: 5px;
    }
    list-style: circle;
  }

  h2 {
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  h5 {
    margin-bottom: 5px;
  }
  p {
    text-align: left;
    margin-top: 0px;
  }
}