.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    text-align: left;
  }

  th {
    font-size: 12px;
    font-weight: 600;
    padding: 10px 20px;
    background-color: #E7ECF3;
  }

  td {
    font-size: 14px;
    font-weight: 500;
    color: #4D4D4D;
    padding: 14px 20px;
    border-bottom: 1px solid #EAECF0;
  }

  thead {
    tr {
      th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      .dateColumn {
        white-space: nowrap;
      }
      
      .idColumn {
        font-weight: 600;
      }

      &:hover {
        background-color: #E7ECF3;
        border-radius: 10px;

        td {
          color: black;
        }

        td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .button {
          background-color: #007BD0;
          color: white;

          &:hover {
            background-color: #5BA4D7;
          }
        }
      }
    }
  }
}

.tableInfo {
  margin-top: 10px;
  font-size: 12px;
  color: #6B7280;
  margin-left: 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pageNumbers {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pageNumber {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #E7ECF3;
  border: none;
  cursor: pointer;
  font-size: 12px;

  &:hover, &.active {
    background-color: #007BD0;
    color: white;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #E7ECF3;
  border: none;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;

  &:hover:not(:disabled) {
    background-color: #007BD0;
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.searchBar {
  margin-bottom: 20px;

  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .iconWrapper {
    position: absolute;
    left: 14px;
    top: 52%;
    transform: translateY(-50%);
    color: #acaba8;
    pointer-events: none;
  }

  input {
    width: 100%;
    padding: 12px 12px 12px 38px;
    font-size: 14px;
    border: 1px solid #dfdedd;
    border-radius: 6px;
    background-color: #fdfdfc;

    &:hover {
      border-color: #143CFF;
    }

    &:focus {
      outline: none;
      border-color: #143CFF;
      box-shadow: 0 0 4px #143CFF;
    }
  }
}