@import "../styles/global.scss";

.wideLayout {
  main {
    padding-left: 0;
    padding-right: 0;
  }
}

.wideLayoutDesc {
  padding-left: 3rem;
  padding-right: 3rem;
}