.contract {
    font-size: 0.9rem;
  
    * {
      color: #464646;
    }
  
    h1 {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: left;
      margin: 1rem 0;
    }
  
    h2,
    h3 {
      font-weight: 600;
      margin: 0.5rem 0;
    }
  
    p {
    //   text-align: justify;
    text-align: left;
      color: black;
      margin: 1rem 0;
    }
    
    span {
        text-align: left;
    }
    .sameLineTitle {
      font-weight: 600;
      margin-right: 5px;
    }

    .thick {
        font-weight: 600;
    }
    a {
      text-decoration: underline;
      color: blue;
    }
    // ol.test {
      
    // }

    .listStart {
        text-indent: 10px;
    }

    li {
        margin-left: 20px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    li.alpha {
      list-style-type: lower-alpha;
    }
    li.roman {
      list-style-type: lower-roman;
    }
    li.num {
      list-style-type: decimal;
    }

    table {
      text-align: left;
      width: 100%;
    }

    tr {
      height: 2.5em;
      background-color: black;
    }

    th {
      color: white;
      background-color: black;
      padding-top: 1.5%;
      padding-bottom: 1.5%;
      width: 30%;
      font-size: smaller;
    }

    td.odd {
      background-color: #d3d3d3;
    }

    td.even {
      background-color: #e5e5e5;
    }

  }
  
  @media screen and (max-width: 720px) {
    .contract {
      p {
        text-align: left;
      }
    }
  }
  