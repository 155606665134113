@import "../../../styles/global.scss";

$background_blue: #b5d1eb;

.lablledIconButton {
  padding: 7px;
  height: 40px;
  width: max-content;
  border: 1px solid $background_blue;
  border-radius: 8px;
  color: $dark_gray;
  fill: $dark_gray;
  stroke: $dark_gray;
  stroke-width: 0;
  transition: 400ms all;
  font-weight: 500;
  cursor: pointer;

  background-color: $background_blue;
  
  >div {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    >div {
      cursor: pointer;
      &:first-child {
        display: flex;
        height: 100%;
        align-items: center;
      }
      &:last-child:not(:only-child) {
        margin-left: 10px;
      }
    }
  }

  &:hover {
    border-color: $fm_blue;
    color: $fm_blue;
    fill: $fm_blue;
    stroke: $fm_blue;
    background-color: #b1b1b122;
  }

  &:focus {
    outline: none;
    // box-shadow: 0 0 3pt 2pt #007bd0;
    // --webkit-box-shadow: 0 0 3pt 2pt #007bd0;
  }

  &:active:not(:disabled) {
    border-color: $dark_blue;
    color: $dark_blue;
    fill: $dark_blue;
    stroke: $dark_blue;
    background-color: #b1b1b188;
    transform: scale(0.9);
  }

  &:disabled {
    color: $ghost_gray;
    border-color: $ghost_gray;
    fill: $ghost_gray;
    stroke: $ghost_gray;
    background-color: transparent;
    cursor: not-allowed;
    >div {
      cursor: not-allowed;
      >div {
        cursor: not-allowed;
      }
    }
  }

  .loadingIcon {
    width: 100%;
    height: 100%;
  }

  .loadingIcon {
    @extend %rotate;
    @extend %loadingIconDimensions;
    height: 75%;
    margin: auto;
  }
}