.pageTitle {
  margin-top: 0px;
    
  span {
    margin-left: 1rem;
    display: inline-block;
    font-weight: 700;
    color: darkgray;
    letter-spacing: 0.05rem;
  }
}