@import "../../../styles/global.scss";

.rotate{
  @extend %rotate;
  @extend %loadingIconDimensions;
  //To position icon centrally in parent
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
}