.container {
  display: flex;
  height: calc(100vh - 152px);
}

.content {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.navigation {
  display: flex;
  max-width: 42rem;
  justify-content: space-between;
  margin-top: 2rem;
}

h2 {
  margin-top: 0;
  font-weight: 600;
  color: black;
}