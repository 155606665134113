@import "../../../styles/global.scss";

.addressInputs {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  
  .roundInput {
      @extend %roundInput;
      width: 100%;
  }
  
  button {
      @extend %roundBtn;
  } 

  input {
    @extend %roundInput;
  }

  input {
      width: 100%;
  }

  display: flex;
  flex-direction: column;
}

.roundInput {
  @extend %roundInput;
}

.formError {
  color: #bf1650;
}

.formError::before {
  display: inline;
  content: "⚠ ";
}