@import "../../../../styles/global.scss";
@import "../ifta-shared.module.scss";

.IFTASelect {
  width:100%;

  label {
    @extend %iftaLabel;
    top: -78px;
  }
  
  select {
    @extend %iftaField;
    padding: 35px 20px 20px;
    -webkit-appearance: none;
  }

  select:focus + label {
    @extend %iftaFocusLabel;
  }

  .arrow {
    position: relative;
    text-align: right;
    right: 15px;
    top: -52px;
    height: 0px;
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
}