@import "../../../styles/global.scss";
@import "../../register/Register.module.scss";

.addCard {
  .desc {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $ghost_gray;
  }

  h2 {
    text-align: center;
    padding-top: 0px;
    margin-top: 0pc;
  }

  form {
    width: 80%;
    margin: auto;

    button {
      width: 120px;
      margin: auto;
    }
  }
}

.addCardWidget {
  width: 80%;
  margin: auto;
  border: 1px solid $ghost_gray;
  border-radius: 20px;
  padding: 20px;
  min-height: 520px;
  height: fit-content;
}

.card {
  width: 80%;
  margin: auto;
  height: 100%;

  .loading {
    height: 100%;
    margin-top: 25%;
  }

  form {
    button {
      @extend .btnPrimary;
      animation: fadeIn 1s;
      margin-top: 20px;
    }
  }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.setupComplete {
  width: fit-content;

  p {
    text-align: left;
  }

  button {
    margin: auto;
    margin-top: 40px;
  }
}