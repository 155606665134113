@import "../../styles/global.scss";

.submitBtnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding-top: 0;
  margin: 2rem auto;

  img {
    @extend .globalLoadingSpinner;
    margin: auto;
  }

  button[type="submit"] {
    @extend %roundBtn;
    width: 100%;
    max-width: 20rem;
    margin-top: 0;
    padding-top: 0;
  }
}

.register {
  @media only screen and (min-width: 768px) {
    max-width: 600px; 
  }
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  .desc {
    h3 {
      text-align: center;
    }
  }
  
  form {
    justify-content: center;
    align-items: center;

    button[type="submit"] {
      @extend %btnPrimary;
      margin: auto;
      margin-top: 10px;
    }
  }

  label {
    width: 100%;
  }

  .passwordRules {
    font-size: small;
    em {
      color: $light_gray;
    }
  }

  .registerCheckboxes {
    width: 90%;
    margin: auto;
    >div {
      align-items: flex-start;
      margin-bottom: 10px
    };
  }
}

.widget {
  padding: 20px;
  border: 1px solid $light_gray;
  border-radius: 20px;
  margin-bottom: 20px;
}

.orderDesc {
  @extend .widget;
  display: flex;
  flex-direction: column;

  >h2 {
    padding-top: 0;
    margin-top: 0;
    color: $light_black;
  }
  >div {
    @extend .row;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid $light_gray;
    }

    >div {
      @extend .col;
    }
  }

  .col {
    &:first-child {
      display: flex;
      flex-direction: column;
    }
    &:last-child {
      @extend .price;
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
    >span {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .orderItem {
    border-top: 1px solid $light_gray;
    >div {
      &:first-child {
        span {
          &:last-child {
            font-size: smaller;
            height: 30px;
            display: flex;
            align-items: center;
            color: $dark_gray;
            >span {
              &:first-child {
                padding-right: 10px;
              }
              svg {
                fill: $light_gray;
              }
            }  
          }
          @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            justify-content: left;
            justify-items: left;
          }
        }
      }
    }
  }
  .total {
    font-weight: 500;
  }
  .price {
    >span {
      @extend .tabularNum;
      &:not(:last-child) {
        text-decoration: line-through;
        font-weight: normal;
        @media only screen and (min-width: 768px) {
          padding-right: 10px;
        }
      }
    }
  }
}

.selfPayFormsWidget {
  @extend .widget;
  
  form {
    display: flex;
    flex-direction: column;
  }
}

.sideBySideInputs {
  width: 100%;
  display: flex;
  flex-direction: row;

  input {
    width: 50%;

    &:nth-of-type(1) {
      margin-right: 5px;
    }

    &:nth-of-type(2) {
      margin-left: 5px;
    }
  }

  .phoneInput {
    width: 50%;
    margin-left: 5px;
  }
}

.backButton {
  @extend .btnTertiary;
  padding-left: 0;
}

.policyWrapper {
  margin: 2rem auto;
  height: 400px;
  padding: 1.5rem;
  overflow-y: scroll;
  border: 1px solid #a9a9a9;
  border-top-left-radius: 2rem;
}

.checkboxContainer {
  display: inline-flex;
  margin-top: 20px;
  
  input {
    width: auto;
  }
  
  label {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.patientSelfPayError {
  text-align: center;
  p {
    &::first-letter {
      text-transform: capitalize,
    }
  }
}

.phoneInput {
  width: 50%;
}

.registerSuccess {
  width: 80%;
  max-width: 500px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  p {
    line-height: 1.5em;
    text-align: center;
  }

  .badges {
    display: flex;
    margin-top: 3rem;

    img {
      height: 80px;

      @media (max-width: 720px) {
        height: 50px;
      }
    }

    // google store image has padding internal to it
    // to equalize, we add some to the app store image
    .apple {
      img {
        padding: 13px;

        @media (max-width: 720px) {
          padding: 8px;
        }
      }
    }
  }
}

.roundInput {
  @extend .roundInput;
  width: 100%;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .inputAndLabel {
    display: flex;
    flex-direction: row;
    width: 100%;

    label {
      width: 50%;
      text-align: left;
      margin-top: auto;
      margin-bottom: auto;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  
    input {
      width: 50%;
    }
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  >div {
    margin-left: 20px;
    margin-bottom: 10px;
    >input {
      margin-right: 20px;
    }
  }
}

.beforeYouRegister {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 20px;
  border: 1px solid $ghost_gray;
  border-radius: 5px;

  h1 {
    text-align: center;
  }

  button[type="submit"] {
    @extend .btnPrimary;
    width: 120px;
    margin: auto;
  }

  .beforeInfoItems {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;

    .beforeInfoItem {
      display: flex;
      flex-direction: row;
      >div {
        &:first-child {
          width: 50px;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 10px;
          margin-right: 20px;
        }
        &:last-child {
          width: 80%;
          p {
            text-align: left;
          }
        }
      }
    }
  }

}