@import "../../styles/global.scss";

.login {
  width: 100%;
  height: $no_header;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 85%;
    max-width: 340px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    >div {
      margin-bottom: 5px;
    }

    input {
      &[type="email"] {
        text-transform: lowercase;
      }
    }

    button {
      @extend %roundBtn;
    }
  }

  .error {
    text-align: center;
    font-weight: 600;
    color: crimson;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .forgotPassword {
    text-align: center;
  }
}
