.modalOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modalContent {
  position: absolute;
  left: 20%;
  right: 20%;
  top: auto;
  bottom: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  max-height: 97vh;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  outline: none;
  overflow: auto;
  overflow-y: auto;
  background: #fff;
}