.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px;
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}