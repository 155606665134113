@import "../../styles/global.scss";

.digestHeader {
  display: flex;
  flex-direction: column;

  > h1 {
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom: 0;
  }

  > span {
    text-transform: lowercase;
    color: $light_blue;
    margin-top: 0;
  }
}

.homeButton {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;

  .backArrow {
    border: solid $light_black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
    transition: all 400ms;
  }

  > span {
    font-size: 1rem;
    margin-left: 0.2rem;
    transition: all 0.4s;
  }

  &:hover > span {
    margin-left: 0.4rem;
    color: $fm_blue;
  }

  &:hover .backArrow {
    border: solid $fm_blue;
    border-width: 0 2px 2px 0;
  }
}