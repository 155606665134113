@import "../../../../styles/global.scss";
@import "../Challenges.module.scss";

.downloadChalReport {
  h3 {
    text-align: center;
  }
}

#canvasWrapper {
  display: block;
  visibility: hidden;
  width: 900px;
  height: 400px;
}