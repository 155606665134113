.container {
  max-width: 42rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  font-size: 14px;
  color: black;
  cursor: default;

  &::after {
    content: " *";
    color: #ef4444;
  }
}

.preferenceValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  font-size: 12px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background-color: #f4f4f5;
  }

  &.empty {
    color: #71717a;
  }

  &.error {
    border-color: #ef4444;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorMessage {
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
}

.summaryContainer {
  width: 100%;
}

.summaryContent {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  > * {
    flex: 1;
    min-width: 0;
  }
}
