@import "../../../styles/global.scss";

.orderHistoryLayout {
  main {
    padding-left: 0;
    padding-right: 0;
  }
}

.desc {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;

  div {
    &:first-of-type {
      display: flex;
      flex-direction: row;

      p {
        width: 60%;
        text-align: left;
      }

      >div {
        width: 40%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.orderHistoryTable {
  display: flex;
  flex-direction: column;
}

.order {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid $fm_blue;
    border-top: 1px solid $fm_blue;
  }
}

.baseRow {
  display: flex;
  flex-direction: row;
}

.tableHeaderCell {
  font-weight: 700;
  color: $light_black;
  overflow-x: hidden;
}

.headerRow {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid $light_gray;
  border-top: 1px solid $light_gray;
}

.orderHistoryHeader {
  @extend .baseRow;
  @extend .headerRow;
  .col {
    @extend .tableHeaderCell;
  }
}

.orderRow {
  @extend .baseRow;
}

.order {
  &:nth-child(odd) {
    background-color: $bg_gray;
  }

  &:nth-child(even) {
    background-color: white;
  }

  div {
    padding-top: 5px;
    
    &:first-of-type {
      padding-top: 10px;
    }

    &:last-of-type {
      padding-bottom: 10px;
    }
  }
}

.col {
  display: flex;
  // Checkbox
  &:nth-of-type(1) {
    width: 5%;
    justify-content: center;
  }

  // Date
  &:nth-of-type(2) {
    width: 15%;
  }

  // Order Contents
  &:nth-of-type(3) {
    width: 32.5%;
  }

  // Order Status
  &:nth-of-type(4) {
    width: 20%;
  }

  // Quantity
  &:nth-of-type(5) {
    width: 10%;
    justify-content: right;
  }

  // Cost
  &:nth-of-type(6) {
    width: 15%;
    display: flex;
    justify-content: right;
    margin-right: 2.5%;
  }
}

.pdfDownloadButton {
  @extend %btnTertiary;

  margin-left: auto;
  display: flex;

  label {
    margin-right: 10px;
  }

  &:hover {
    label {
      color: $fm_blue;
    }

    .pdfDownloadButtonIcon {
      .pdfIconLoaded {
        fill: $fm_blue;
      }
    }
  }

  &:disabled {
    .pdfDownloadButtonIcon {
      .pdfIconLoaded {
        fill: $ghost_gray;

        &:hover {
          fill: $ghost_gray;
        }
      }
    }

    &:hover {
      fill: $ghost_gray;
      label { 
        color: $ghost_gray;
      }
    }
  }
  

  .loadingIcon {
    width: 100%;
    height: 100%;
  }

  .pdfDownloadButtonIcon {
    height: 100%;
    margin-left: auto;

    .pdfIconLoaded {
      width: 100%;
      height: 100%;
      fill: $dark_gray;
    }
  }

  .loadingIcon {
    @extend %rotate;
    @extend %loadingIconDimensions;
  }
}

.orderHistory {
  input[type="checkbox"] {
    cursor: pointer;
  }
}