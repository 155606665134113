@import "../../styles/global.scss";

footer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: $no_sidebar;
  box-sizing: border-box;
  color: white;
  height: 80px;
  padding: 10px 30px;
  background-color: #485461;
  background-image: linear-gradient(315deg, #4a4a4a 0%, #4a4a4a 90%);

  ul,
  li,
  p,
  a {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 0.9rem;
  }
}

.copyright {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: #ffffff88;
}

.legal {
  text-align: right;
}
