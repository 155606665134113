.container {
  max-width: 42rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.currencyRow {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.dropdownWrapper {
  display: flex;
  gap: 20px;
  flex: 1;
  align-items: center
}

.dropdownValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  font-size: 12px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background-color: #f4f4f5;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 14px;
  color: black;
  cursor: default;
  font-weight: 500;
}

.divider {
  border-top: 1px solid #e4e4e7;
  width: 90%;
  align-self: center;
  margin: 10px 0;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 600;
}

.error {
  color: #ef4444;
  font-size: 14px;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.pricingTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th, td {
    text-align: left;
  }

  th {
    // background-color: #E0E0E0;
    background-color: #dcdfe4;
    font-weight: 600;
    font-size: 12px;
    padding: 12px 14px;
    color: #4D4D4D;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  td {
    padding: 4px;
    font-size: 14px;

    &:first-child {
      padding-left: 14px;
    }
  }

  tr{
    &:hover td {
      background-color: #ebecf0;
    }

    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.input {
  width: 100%;
  padding: 8px 6px;
  border: 1px solid #e4e4e7;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fafafa;
  
  &:focus {
    outline: none;
    border-color: #2563eb;
  }
}

.addSku {
  margin-bottom: 20px;
  width: 100%;
}

.addSkuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 10px 14px;
  font-size: 14px;
}
