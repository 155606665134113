@import "../../styles/global.scss";

.dashlayout {
  .content {
    display: flex;
  }

  main {
    min-height: $no_header_no_footer;
    width: $no_sidebar;
    background-color: #f5f6fa;
    padding:3rem;
    display:flex;
    flex-direction:column;
  }
}
