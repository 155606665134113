@import "../../../styles/global.scss";

.dayToDay, .miniDayToDay {
  .dayToDayNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;

    .datePicker {
      margin: 2rem 0;
    }

    .excelExportContainer {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: 0;
    }
  }

  p {
    text-align: left;
  }

  .charts {
    h2 {
      display: block;
      font-size: 1.5em;
      font-weight: 800;
      color: $dark_gray;
      text-align: center;
      margin-top: 3rem;
    }

    .rotate{
      @extend %rotate;
      @extend %loadingIconDimensions;
      //To position icon centrally in parent
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -15px 0 0 -15px;
    }
    
    #multichartCanvasWrapper, #symptomMultichartCanvasWrapper {
      display: block;
      position: relative;
      width: 100%;
      height: 350px;
      margin: 0px auto 20px auto;
      padding-right: 24px;
    }

    .canvasWrapper {
      display: block;
      position: relative;
      width: 100%;
      margin: 0px auto 20px auto;
    }

    #sleepCanvasWrapper,
    #stressCanvasWrapper,
    #stoolMultichartCanvasWrapper,
    #symptomMultichartCanvasWrapper {
      @extend .canvasWrapper;
      height: 180px;
    }

    #stressCanvasWrapper, #stoolMultichartCanvasWrapper {
      height: 230px,
    }

    #stoolMultichartCanvasWrapper {
      padding-right: 14px;
    }

    #sleepCanvasWrapper {
      padding-right: 22px;
    }

    #stressCanvasWrapper {
      padding-right: 25px;
    }

    #symptomMultichartCanvasWrapper {
      height: 200px;
      padding-right: 13px;
    }

    #medsCanvasWrapper {
      @extend .canvasWrapper;
      padding-right: 55px;
      width: 100%;
    }
  }
}

.miniDayToDay {
  .charts {
    #multichartCanvasWrapper {
      
    }
  }
}