@import "../../../styles/global.scss";

.clinicianLinkMenu {
  display: flex;
  flex-direction: column;
  width: 100%;

  .selected {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border: 1px solid $ghost_gray;
    padding: 10px;
    border-radius: 10px;

    div {
      &:first-child {
        width: 40%;
        text-align: right;
        font-weight: 600;
      }
  
      &:last-child {
        width: 60%;
  
        ul {
          li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 40px;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
      
            span {
              &:last-of-type {
                @extend .deleteButton;
              }
            }
          }
        }
      }
    }
  }
}

.deleteButton {
  top: 2rem;
  background: white;
  color: $fm_blue;
  font-weight: 800;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $fm_blue;
  border-radius: 50%;
  transition: all 400ms;
  right: 2rem;
  font-size: 0.8rem;

  &:hover {
    background: $fm_blue;
    color: white;
  }
}