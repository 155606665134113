.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 10px;
  
  .leftSection {
    display: flex;
    align-items: center;
  }

  h1 {
    margin: 0;
    margin-left: 10px;
    font-size: 26px;
    color: black;
  }
}
