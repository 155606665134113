@import "../../../styles/global.scss";

p {
  width: 100%;
  text-align: center;
}

.chalPromptForm {
  h1 {
    text-align: center;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .inputs {
    width: 100%;
    justify-content: center;
    z-index: 1;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid $fm_blue;

    &:last-of-type {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
  
    }
  }

  .basicOptions {
    @extend .inputs;
  }

  .advancedOptions {
    @extend .inputs;

    p {
      margin-bottom: 10px;
    }

    .advancedOptionsInputs {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .numericInputContainer {
      margin: auto;
      display: flex;
      flex-direction: row;
      height: 30px;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;

      label {
        width: 50%;
        margin-top: auto;
        margin-bottom: auto;
        text-align: right;
      }

      select {
        @extend %roundInput;
        border-radius: 10px;
        width: 30%;
        margin-left: 20px;
        height: 100%;
      }

      >span {
        width: 10%;
        display: grid;
        place-items: center;
      }
    }
  }

  button[type="submit"] {
    @extend %btnPrimary;
    margin: auto;
    margin-top: 30px;
  }
}

.fodmapLists {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 0;
  margin-top: 0;
  width: 100%;
}

.radioList {
  width: 45%;;
  margin: 0 70px 0 70px;

  h3 {
    color: $dark_gray;
    text-align: center;
    margin-bottom: 0;
  }
}

.languageSelect {
  width: fit-content;
  margin: auto;
}

.reminderForm {
  h1 {
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin-left: 40px;
      margin-right: 40px;
  
      &:first-child {
        @extend %btnSecondary;
      }
      &:last-child {
        @extend %btnPrimary;
      }
     }
  }
}

.success,.error {
  text-align: center;
}