@import "../../styles/global.scss";

.prelayout {
  main {
    width: 100%;
    min-height: $no_header;
    background-color: #f5f6fa;

    @media (min-width:480) {
      padding: 3rem;
    }

    @media (min-width:0) {
      padding: 20px;
    }
  }
}
