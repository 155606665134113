@import "../../styles/global.scss";
@import "../../components/inputs/SearchInput/SearchInput.module.scss";
@import "../home/add-patient/AddPatientButton/AddPatientButton.module.scss";
@import "../../layouts/dash/DashLayout.module.scss";
@import "../../components/buttons/ChallengePromptModalButton/ChallengePromptModalButton.module.scss";

.siteOverviewLayout {
  main {
    padding-left: 0;
    padding-right: 0;
  }
}

.desc {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $light_gray;

  h1 {
    margin-top: 0px;
      
    span {
      margin-left: 1rem;
      display: inline-block;
      font-weight: 700;
      color: darkgray;
      letter-spacing: 0.05rem;
    }
  }

  p {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;

  &:hover {
    &:not(.mainHeader):not(.tableHeader) {
      span {
        color: $fm_blue;
      }
      .rpmCol {
        span {
          color: $dark_gray;
        }
      }
    }
  }

  span {
    color: $dark_gray;
  }
}

.row.disabledRow {
  &:hover {
    span {
      color: $dark_gray;
    }
  }
}

.row.odd {
  background-color: $darker_bg_gray;
}

.hideButtonCol {
  @extend .colGroup;
  width: 10%;
}

.patientsCol {
  @extend .colGroup;
  color: $light_gray;
  width: 45%;

  span {
    width: 29%;

    &:nth-child(1), &:nth-child(2) {
      width: 45%
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(4) {
      width: 20%;
    }
  }
}

.challengesCol {
  @extend .colGroup;
  width: 45%;

  >span {
    // Date
    &:nth-child(1) {
      width: 30%;
    }
    // Substrate
    &:nth-child(2) {
      width: 25%;
    }
    // Result
    &:nth-child(3) {
      width: 15%;
    }
    // Review status
    &:nth-child(4) {
      display: flex;
      margin: auto;
      justify-content: center;
      height: 100%;
      width: 20%;
      svg {
        fill: $dark_gray;
      }
    }

    // Review status
    &:nth-child(5) {
      display: flex;
      width: 10%;
      align-items: center;
      justify-content: center;
      span {
        background-color: green;
      }
      svg {
        height: 28px;
        fill: $dark_gray;
      }
    }
  }

  .challengeDate {
    @extend .tabularNum;
  }
}

.rpmCol {
  @extend .colGroup;
  width: 10%;
  display: flex;
  align-items: flex-start;
  >span {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}

.rpmButton {
  height: 100%;
  padding-left: 0;

  svg {
    fill: $dark_gray;
    &:disabled {
      fill: $ghost_gray;
    }
  }

  &:hover {
    &:not([disabled]) {
      cursor: pointer;
      svg {
        fill: $fm_blue;
      }
      span {
        color: $fm_blue !important;
      }
    }
  }

  &:active {
    &:not([disabled]) {
      cursor: pointer;
      svg {
        fill: $dark_blue;
      }
      span {
        color: $dark_blue !important;
      }
    }
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.promptButton {
  height: 100%;
  fill: $light_black;

  &[disabled] {
    fill: $light_black;
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover, &:active {
    &:not([disabled]) {
      fill: $fm_blue;
      cursor: pointer;
    }
  }
}

.buttonSvg {
  @extend .promptButton;
  fill: $dark_gray;
}

.siteOverview {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .mainHeader {
    @extend .row;

    div {
      display: flex;
      flex-direction: column;
      justify-content: left;

      span {
        justify-content: left;
        color: $light_black;
        font-weight: 700;
        text-align: left;
      }
    }

    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid $light_gray;
    border-top: 1px solid $light_gray;

    .patientMainHeader {
      display: flex;
      flex-direction: row;
      justify-content: left;

      span {
        margin-right: 0;
        padding-right: 0;
        width: fit-content;
        margin-right: 20px;
      }

      button {
        @extend .btnPrimary;
        height: 100%;
        align-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        $plus_sign_svg: '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 459.325 459.325" style="enable-background:new 0 0 459.325 459.325;" xml:space="preserve"><g><path d="M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193 c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181 c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267 V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282 C441.339,189.487,459.308,207.471,459.319,229.668z"/></g></svg>';

        background-image: inline-svg(svg-string-with-color($plus_sign_svg,#FFFFFF));
        background-repeat: no-repeat;
        background-position-x: 8%;
        background-position-y: 50%;
        padding-left: 35px;
      }

      // .addPatientButton {
      //   span {
      //     font-weight: 100;
      //     overflow: visible;
      //   }
      // }
    }
  }

  .tableHeader {
    @extend .row;
    div {
      span {
        font-weight: 700;
        color: $dark_gray;

        &.smallHeader {
          font-weight: 500;
        }
      }
    }

    height: 3.5rem;
  }
}

.colGroup {
  display: inline-flex;
  display: flex;
  justify-content: center;
  height: 100%;

  &:not(:last-child) {
    border-right: 1px solid $light_gray;
  }

  span {
    height: 100%;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    overflow-y: hidden;
    
    &:first-child {
      padding-left: 20px;
    }

    // &:last-child {
    //   padding-right: 20px;
    // }
  }
}

.resultsWrapper {
  width: 100%;
}

.infoBtn {
  @extend .btnTertiary;
  min-width: 100%;

  &:disabled {
    color: $dark_gray;
    cursor: not-allowed;
  }
}

.siteOverviewWrapper {
  display: flex;
  flex-direction: column;

  .searchInput {
    align-self: center;
    width: 95%;  
    margin-top: 20px;
    font-weight: 600;
    padding-left: 40px;
    background-color: #FFFFFF;
  }  
}

.datePickerContainer {
  display: flex;

  span {
    color: $dark_gray;
    font-weight: 700;
  }
}

.filterWidgets {
  display: flex;
  flex-direction: column;
  justify-content: right;
  justify-items: right;

  div {
    display: flex;
    justify-content: right;
    margin-bottom: 5px;
    
    span {
      &:first-of-type {
        width: 70%;
      }
      &:last-of-type {
        width: 30%;
      }

      display: inline-flex;
      justify-content: right;
      color: $dark_gray;
      font-weight: 700;
      text-align: center;

      select {
        @extend %roundInput;
        margin-right: 20px;
        height: 25px;
        text-transform: capitalize;
      }
    }
  }
}

.loadingIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  img {
    @extend %loadingIconDimensions;
    @extend %rotate;
  }
}

.cancelChallenge {
  fill: $fm_blue;
  background-color: $fm_blue;
}

.btnGroup {
  @extend %btnGroup;
  >button {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.rpmModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;

  >div {
    margin-top: 20px;
  }
}