.addPreference {
  margin-bottom: 20px;
  width: 100%;
}

.addPreferenceButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 10px 14px;
  font-size: 14px;
}

.preferenceValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  font-size: 12px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preferenceList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.preferenceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preferenceInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preferenceId {
  background-color: #143CFF;
  color: white;
  border-radius: 6px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.preferenceName {
  font-size: 14px;
  font-weight: 500;
}

.preferenceActions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.removeButton {
  padding: 4px !important;
  min-width: auto !important;
  border-radius: 6px;

  svg {
    width: 18px;
    height: 18px;
  }
}