@import "../../../styles/global.scss";

.copyBtn {
  .copyIcon {
      color: $light_black;
      path {
          stroke: $light_black;
      }
      &:hover {
          cursor: pointer;
          path {
              stroke: $fm_blue;
          }
      }
  }
}