@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
@import "./styles/global.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  background: $bg_gray;
  position: relative;
  min-height: 100vh;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ol,
ul,
li {
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: none;
  padding-inline-start: 15px;
  -webkit-padding-start: 15px; /* to avoid ugly sidebar in patients page */
}

h1,
h2,
h3,
h4 {
  color: $fm_blue;
}

h1,h2 {
  &::first-letter {
    text-transform: capitalize;
  }
}

a {
  text-decoration: none;
  color: $fm_blue;
}

h1 {
  font-weight: 800;
  &::first-letter {
    text-transform: capitalize;
  }
}

h2 {
  font-weight: 500;
}

strong {
  font-weight: 600;
}

button {
  font-family: inherit;
}

button:disabled {
  cursor: default;
  opacity: 0.7;
}
