.inputGroup {
  display: flex;
  gap: 20px;

  > div {
    flex: 1;
  }
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.preferenceValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  font-size: 12px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background-color: #f4f4f5;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 14px;
  color: black;
  cursor: default;
}