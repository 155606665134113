.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.modalOpen {
    opacity: 1;
  }
}

.modal {
  background-color: #fff;
  border-radius: 8px;
  width: 600px;
  max-width: 90%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.modalOpen {
    transform: scale(1);
    opacity: 1;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 30px;

  h2 {
    color: black;
    margin: 0;
    font-size: 22px;
  }
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #e7e6e1;
    border-radius: 6px;
  }
}

.modalContent {
  max-height: 80vh;
  padding: 0px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}