.policy {
  font-size: 0.9rem;

  * {
    color: #464646;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;
    margin: 1rem 0;
  }

  h2,
  h3 {
    font-weight: 600;
    margin: 0.5rem 0;
  }

  p {
    text-align: justify;
    color: black;
    margin: 1rem 0;
  }

  .sameLineTitle {
    font-weight: 600;
    margin-right: 5px;
  }
}

@media screen and (max-width: 720px) {
  .policy {
    p {
      text-align: left;
    }
  }
}
