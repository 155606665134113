@import "../../../styles/global.scss";

.labelledCheckbox {
  display: flex;
  flex-direction: row;

  label {
    margin-top: auto;
    margin-bottom: auto;
  }
}