@import "../../../styles/global.scss";

%iftaLabel {
  display: block;
  font-size: 12px;
  margin-bottom:-30px;
  position:relative;
  padding:0px 10px 0 20px;
  pointer-events:none;
  top: -70px;

  &::first-letter {
    text-transform: uppercase;
  }
}

%iftaField {
  border:0;
  border-radius:20px;
  padding: 45px 20px 20px;
  margin: 0 0 20px;
  width:100%;
  -webkit-box-shadow:inset 0 0 0 1px #ccc;
          box-shadow:inset 0 0 0 1px #ccc;
  &::-webkit-input-placeholder {
    color: #dfdfdf;
  }
  &::-moz-placeholder {
    color: #dfdfdf;
  }
  &:-ms-input-placeholder {
    color: #dfdfdf;
  }
  &::-ms-input-placeholder {
    color: #dfdfdf;
  }
  &::placeholder {
    color: #dfdfdf;
  }
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus::-moz-placeholder {
    color: transparent;
  }
  &:focus:-ms-input-placeholder {
    color: transparent;
  }
  &:focus::-ms-input-placeholder {
    color: transparent;
  }
  &:focus::placeholder {
    color: transparent;
  }
  &::-webkit-input-placeholder {
    color: #dfdfdf;
  }
  &::-moz-placeholder {
    color: #dfdfdf;
  }
  &:-ms-input-placeholder {
    color: #dfdfdf;
  }
  &::-ms-input-placeholder {
    color: #dfdfdf;
  }
  &::placeholder {
    color: #dfdfdf;
  }
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus::-moz-placeholder {
    color: transparent;
  }
  &:focus:-ms-input-placeholder {
    color: transparent;
  }
  &:focus::-ms-input-placeholder {
    color: transparent;
  }
  &:focus::placeholder {
    color: transparent;
  }  
  &:focus {
    -webkit-appearance:none;
    outline:none;
    -webkit-box-shadow:inset 0 0 0 2px #007bff;
            box-shadow:inset 0 0 0 2px #007bff;
  }
}

%iftaFocusLabel {
  color: $fm_blue;
}