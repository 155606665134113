@import "../../../styles/global.scss";
@import "../../../components/orders/AddAddress/AddressInputs.module.scss";

.addPatient {
  h1 {
    text-align: center;
  }
}

.newUser {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
  width: 70%;
  border: 1px solid $fm_blue;
  border-radius: 1rem;
  align-items: center;

  >div {
    width: 100%;
  }

  .refCheckbox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    input {
      width: fit-content;
      margin-right: 10px;
    }
  }

  input {
    width: 100%
  }
  
  button {
    @extend %roundBtn;
    width: 100%;
    margin-top: 20px;
  }

  .error {
    text-align: center;
    font-weight: 700;
    color: crimson;
    margin-top: 1rem;
  }
}

.linkClinicians {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  justify-content: center;
  align-items: center;

  select {
    margin-bottom: 1rem;
    width: 100%;
  }

  .btnPrimary {
    width: fit-content;
    margin: auto;
    margin-top: 10px;
  }
}

.btnGroup { 
  @extend %btnGroup;

  justify-content: center;
  button {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.success {
  margin: auto;
}

.formError {
  @extend .formError;
  margin-top: 0;
}

.patientDetailsIdt {
  width: 100%;

  div {
    p {
      font-style: italic;
    }

    .formError {
      font-style: normal;
    }
  }
  
  .idtMail {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .idtError {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.orderDevice {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  padding: 20px;

  input {
      @extend %roundInput;
  }
  
  h3 {
      color: $dark_gray;
  }

  p {
    text-align: left;
  }
}

.radioButtons {
  li {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    >span {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

// .languageSelect {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   select {
//     font-size: 30px;
//     border: none;
//   }
//   label {
//     padding-top: 12px;
//     padding-right: 10px;
//     text-transform: capitalize;
//   }
// }

.loading {
  @extend %loadingIconDimensions;
  @extend %rotate;
}

.noDevice {
  display: flex;
  margin-left: 15%;
  margin-right: 15%;
  >div {
    display: flex;
    flex-direction: column;
    > label {
      margin-top: 15px;
    }
  }
}