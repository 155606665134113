@import "../../styles/global.scss";
@import "../../keyframes.scss";
@import "../../components/buttons/ChallengePromptModalButton/ChallengePromptModalButton.module.scss";

// Home.js
.home {
  h1 {
    display: inline-block;
    font-size: 20px;
    font-weight: 900;
    margin: 0;

    span {
      margin-left: 1rem;
      display: inline-block;
      font-weight: 700;
      color: darkgray;
      letter-spacing: 0.05rem;
    }
  }
}

// Shared
.intro {
  @extend %explanation;
  position: relative;
  padding: 1.5rem 0;

  > * {
    display: inline-block;
    margin: 1rem 0;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3rem;
    width: calc(100% + 6rem);

    border-bottom: 1px solid $ghost_gray;
  }
}

// WithPatients.js
.patients {
  margin: 1rem 0;
  padding-inline-start: 0;
  -webkit-padding-start: 0;

  .strip {
    display: flex;
    align-items: center;
    height: 4rem;

    span {
      width: 7%;
      overflow-x: hidden;
      padding: 0 1rem;
      text-align: center;
      font-weight: 700;
      color: $dark_gray;

      // identifier / name
      &:first-of-type {
        width: 18%;
        text-align: left;
        font-weight: 400;
        color: black;
        padding-left: 0;
      }

      // email
      &:nth-child(2) {
        width: 35%;
        text-align: left;
        font-weight: 400;
        color: black;
      }

      &:nth-child(5) {
        width: 12%;
      }

      &:not(:first-child):not(:nth-child(2)):not(:nth-child(5)):not(:nth-child(8)):not(:last-child) {
        width: 8%;
      }

      &:last-child,&:nth-child(8) {
        width: 10%;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          display: flex;
          flex-direction: column;
          align-self: center;
        }
      }
    }

    .scaleOut {
      margin: 0 auto;
      width: 1em;
      height: 1em;
      background-color: #333;
      border-radius: 100%;
      -webkit-animation: sk-scaleout 1.5s infinite ease-in-out;
      animation: sk-scaleout 1.5s infinite ease-in-out;
    }
  }

  .titleHeader {
    border-bottom: 1px solid $ghost_gray;

    span {
      font-size: 15px;
      font-weight: 700 !important;
      // font-size: 1rem;
      color: $light_black;
      text-align:center;
    }
  }

  .notUser {
    span {
      color: $light_gray !important;
    }
    cursor: default;
  }

  .user {
    &:hover span {
      color: $fm_blue !important;
    }
  }
}

.headStrip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom:1rem;
}

.stickyContainer {
  @extend %stickyHeader;
}

.resendButton {
  @extend .promptButton;
}

.bellIconContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}