.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 90%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalHeader {
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    height: 8px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), transparent);
    pointer-events: none;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #71717a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background-color: #f4f4f5;
  }
}

.modalContent {
  padding: 0.25rem 2rem;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.section {
  margin-bottom: 2rem;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modalFooter {
  padding: 0.75rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 10;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    height: 8px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.05), transparent);
    pointer-events: none;
  }
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.preferencesTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th, td {
    text-align: left;
  }

  th {
    background-color: #dcdfe4;
    font-weight: 600;
    font-size: 12px;
    padding: 8px 14px;
    color: #4D4D4D;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  td {
    padding: 4px 14px;
    font-size: 14px;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr {
    &:hover td {
      background-color: #ebecf0;
    }

    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.noPreferences {
  color: #71717a;
  font-size: 0.875rem;
  font-style: italic;
  text-align: left;
}

.inputRow {
  display: flex;
  gap: 1rem;
  width: 100%;

  > * {
    flex: 1;
    min-width: 0;
  }
}

.columns2 {
  > * {
    width: calc(50% - 0.5rem);
  }
}

.columns3 {
  > * {
    width: calc(33.333% - 0.667rem);
  }
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  font-size: 14px;
  color: black;
  cursor: default;
}

.dropdownValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  font-size: 12px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background-color: #f4f4f5;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subsection {
  margin-top: 1rem;

  h4 {
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 0.25rem;
    margin-bottom: 0.75rem;
    color: #52525b;
  }
}
