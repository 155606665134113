@import "../../../styles/global.scss";

.promptButton {
  height: 100%;
  fill: $light_black;

  &[disabled] {
    fill: $light_black;
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover {
    &:not([disabled]) {
      fill: $fm_blue;
      cursor: pointer;
    }
  }
  &:active {
    &:not([disabled]) {
      fill: $dark_blue;
      cursor: pointer;
    }
  }
}

.bellIconContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}