@import "../../../styles/global.scss";

.languageSelect {
  display: flex;
  flex-direction: row;
  >span {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      font-size: 20px;
    }
  }
}

.languageSelectWidget {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid $ghost_gray;
  border-radius: 10px;
  padding: 5px 20px;

  select {
    border: none;
  }
  label {
    display: flex;
    padding-right: 10px;
    text-transform: capitalize;
  }
}

.I18NextLanguageSelect {
  >div {
    >span {
      select {
        width: 20px;
        font-size: 12px;
      }
    }
  }
}