@import "../../../styles/global.scss";

.sections {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    div {
      cursor: pointer;
      font-weight: 500;
      color: $light_gray;
      transition: all 400ms;
      padding: 0.7rem 1.5rem;

      &:hover {
        color: $dark_gray;
      }
    }
  }

  #currentSection {
    box-sizing: border-box;
    border: 1px solid $fm_blue;
    border-bottom: none;
    box-shadow: 0 2px 0 white;
    border-radius: 1rem 1rem 0 0;
    color: $fm_blue;
    font-weight: 700;
    background: white;
    z-index: 2;
  }