.aside {
  width: 20%;
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: none;
  box-shadow: none;
  text-wrap: none;
  width: auto;
}

.link {
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  height: 2.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #374151;
  margin-bottom: 0.5rem;
  transition: background-color 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: #ebecf0;
  }

  &.active {
    background-color: #dcdfe4;
  }
}
