@import "../../styles/global.scss";
@import "../../components/dialogs/DialogBase/DialogBase.module.scss";

.dashAdmin {
    height: $no_header;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        width: 60%;
        flex-direction: column;

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
    
            > div {
                width: 60%;
                margin-top: 5px
            }
    
            button[type="submit"] {
                @extend %roundBtn;
                margin-top: 2rem;
            }
        }
    }
}

.dashAdminNav {
    width: 100%;

    button {
        @extend %roundBtn;
        margin: auto;
        width: 100%;
        margin-top: 50px;
        &:not(:first-child) {
            background-color: $alert_red;
        }
    }
}

.dialogContent {
    .btnPrimary {
        @extend .btnPrimary;
        margin: auto;
        width: 60%;
        margin-top: 10px;
    }
}

.copyBtn {
    .copyIcon {
        color: $light_black;
        path {
            stroke: $light_black;
        }
        &:hover {
            cursor: pointer;
            path {
                stroke: $fm_blue;
            }
        }
    }
}

.resetLink {
    display: flex;
    flex-direction: row;
    align-items: center;
}