@import "../../../styles/global.scss";

.resultsMarker {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  display: inline-block;
}

.poorlyDigested {
  @extend .resultsMarker;
  background-color: $alert_red;
}
.adequatelyDigested {
  @extend .resultsMarker;
  background-color: $success_green;
}