@import "../../../styles/global.scss";

.errorPage {
  min-height: $no_header;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }
}
