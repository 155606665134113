@import "../../styles/global.scss";
@import "../../layouts/layouts.module.scss";

.ordering {  
  select, input {
    @extend %roundInput;
    height: 30px;
    width: 80%;
  }

  form {
    background-color: white;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: 100%
  }

  .summary {
    display: flex;
    flex-direction: column;
    width: 120px;
    margin-left: auto;
    padding-top: 10px;
    border-top: 1px solid $ghost_gray;

    .orderTotal {
      text-align: right;
      margin-bottom: 20px;
    }
  }
}

.error {
  padding-left: 3rem;
  padding-right: 3rem;

  h1 {
    text-align: center;
  }

  button {
    margin: auto;
    a: {
      color: red;
    }
  }
}

.layout {
  @extend .wideLayout;
}

.desc {
  @extend .wideLayoutDesc;
  display: flex;
  flex-direction: column;

  div {
    &:first-of-type {
      display: flex;
      flex-direction: row;

      p {
        width: 60%;
        text-align: left;
      }
    }

    .information {
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      align-items: flex-end;
    
      div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          align-items: center;
        
          &:first-of-type {
            margin-right: 10px;
          }
        }
      }
    }
  }
}



.row {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0px;
  }

  span {
    display: flex;
    justify-content: right;
    // Item select
    &:nth-child(1) {
      width: 33%;
      justify-content: left;
      select {
        width: 90%;
      }
    }

    // Quantity
    &:nth-child(2) {
      width: 17%;
      display: flex;
      justify-content: center;
    }

    // Unit price
    &:nth-child(3) {
      @extend .tabularNum;
      width: 10%;
      
      &.discounted {
        text-decoration: line-through;
      }
    }

    // Unit Discount
    &:nth-child(4) {
      @extend .tabularNum;
      width: 25%;
    }

    // Discounted Total
    &:nth-child(5) {
      @extend .tabularNum;
      width: 15%;
    }
  }
}

.orderItemRow {
  @extend .row;
}

.orderWidgetSummaryRow {
  @extend .row;

  margin-top: 10px;
}

.accumulatedDiscountRow {
  @extend .orderWidgetSummaryRow;
  color: $alert_red;
}

.orderFormRows {
  margin-bottom: 20px;
}

.orderSummaryRows {
  @extend .orderFormRows;

  div {
    &:first-of-type {
      span {
        padding-top: 10px;

        &:nth-last-of-type(1),&:nth-last-of-type(2) {
          border-top: 1px solid $ghost_gray
        }
      }
    }
  }
}

.orderWidgetHeader {
  @extend .row;
  font-weight: 700;
  color: $light_black;
  overflow-x: hidden;  
}

.orderingModalContent {
  h1 {
    text-align: center;
  }

  .btnPrimary {
    width: 150px;
    margin: auto;
  }
}

.orderingModalSummaryTable {
  width: 80%;
  margin: auto;
  tr {
    td,th {
      padding-bottom: 5px;
      &:nth-child(1) {
        text-align: left;
        width: 40%;        
      }
      &:nth-child(2),&:nth-child(3) {
        text-align: right;
        width: 30%;
      }
      &:nth-child(3) {
        @extend .tabularNum;
      }
    }
  }

  .tableDiscountRow {
    td {
      color: $alert_red;
    }
  }

  tfoot {
    tr {
      &:first-of-type {
        td {
          padding-top: 10px;
          &:last-child {
            border-top: 1px solid $ghost_gray;
          }
        }
      }
    }
  }
}