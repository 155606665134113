@import "../../styles/global.scss";

.resetPassword {
  max-width: 720px;
  padding: 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 350px;
    margin: auto;
    div {
      margin-top: 20px;
      width: 100%;
    }
  }

  button {
    margin-top: 40px;
    @extend %btnPrimary;
  }
}

.formValidationError {
  color: $alert_red;
  font-weight: 600;
}