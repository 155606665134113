.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.fixedWidth {
    flex: 0 0 auto;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;

    .label {
      color: #999999;
    }

    .input {
      background-color: #f5f5f5;
      color: #999999;
      cursor: not-allowed;

      &:hover, &:focus {
        border-color: #dfdedd;
        box-shadow: none;
      }
    }
  }
}

.label {
  font-size: 14px;
  color: black;
  cursor: default;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.input {
  width: 100%;
  padding: 10px 12px;
  font-size: 12px;
  border: 1px solid #dfdedd;
  border-radius: 6px;
  background-color: #fdfdfc;
  color: black;

  &::placeholder {
    color: #acaba8;
  }

  &:hover {
    border-color: #143CFF;
  }

  &:focus {
    outline: none;
    border-color: #143CFF;
    box-shadow: 0 0 4px #143CFF;
  }

  &.error {
    border-color: #ff0000;
    box-shadow: none;
  }
}

.description {
  resize: vertical;
  min-height: 100px;
}

.errorMessage {
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
}

.requiredAsterisk {
  margin-left: 4px;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}
