@import "../.../../../../../styles/global.scss";

.filledRadioButton {
  width: 100%;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: $fm_blue;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $fm_blue;;
      border-radius: 100%;
      background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $fm_blue;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

  [type="radio"]:checked + label {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    color: #fff;
    @extend %checkedUnderline;
  }

  label {
    display: block;
    text-align: center;
    top: 30px;
    margin-left: 13px;
    padding-right: 16%;
    width: 100%;
    font-weight: 500;
    @extend %uncheckedUnderline;
  }

  div {
    width: 100%;
    padding: 18px;
    border: 2px solid $fm_blue;
    border-radius: 20px;
    margin-bottom: 0;
  }

  input:checked ~ div {
    background-color: $fm_blue;
  }

  padding: 0;
  margin-bottom: 0;
}

%underline {
  text-decoration: underline;
  transition: text-decoration-color 300ms;
  text-underline-offset: 3px;
}

%checkedUnderline {
  @extend %underline;
  text-decoration-color: $fm_blue;
  &:hover {
    text-decoration-color: #fff;
  }
}

%uncheckedUnderline {
  @extend %underline;
  text-decoration-color: #fff;
  &:hover {
    text-decoration-color: $fm_blue;
  }
}