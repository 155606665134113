@import "../../DashAdmin.module.scss";

.dashAdminAddOrder {
  height: $no_header;
  display: flex;
  flex-direction: column;
  width: 100%;

  .complete,.error {
    flex-direction: column;
    display: flex;
    text-align: center;
    align-items: center;

    >button {
      @extend .btnPrimary;
    }
  }

  h1 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    margin: auto;
    width: 80%;

    .formInputs {
      width: 40%;
      display: flex;
      flex-direction: column;
      padding-right: 10px;
    }
  
    .basket {
      @extend .widget;
      width: 60%;
      padding-left: 10px;

      >button[type="submit"] {
        margin-top: 20px;
      }


      table {
        min-width: 100%;
        border-collapse: collapse;
        thead, tbody, tfoot {
          tr {
            th, td {
              color: $light_black;
            }

            th {
              &:nth-child(1) {
                text-align: left;
              }

              &:not(:nth-child(1)) {
                text-align: right;
              }

              &:nth-child(2) {
                padding-right: 10px;
              }
            }

            td {
              &:nth-child(1) {
                width: 38%;
              }

              &:not(:nth-child(1)) {
                text-align: right;
              }
    
              &:nth-child(2) {
                @extend .tabularNum;
                width: 10%;
              }
    
              &:nth-child(3) {
                width: 9%;
                padding-right: 10px;
              }
    
              &:nth-child(4) {
                @extend .tabularNum;
                width: 10%;
              }
    
              &:nth-child(5) {
                @extend .tabularNum;
                width: 15%;
              }
    
              &:nth-child(6) {
                width: 8%;
              }
    
              &:nth-child(7) {
                max-width: 10%;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              margin-bottom: 10px;
            }
          }
        }

        tfoot {
          tr {
            margin-top: 10px;
          }
          td {
            border-top: 1px solid $ghost_gray;
            padding-top: 20px;

            &:first-child {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  
  .prePaidPriceSummary {
    width: 50%;
  }
}

.removeFromBasketButton {
  color: $dark_gray;
  cursor: pointer;
  &:hover {
    color: $fm_blue;
  }
}

.itemWidget {
  @extend .widget;
  width: 100%;
}

.widget {
  display: flex;
  flex-direction: column;
  border: 1px solid $ghost_gray;
  border-radius: 20px;
  padding: 20px;
  height: fit-content;

  h4 {
    margin-top: 0px;
    text-align: center;
    color: $light_black;
  }
}