.phoneInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  
    .label {
      font-size: 14px;
      color: black;
      cursor: default;
    }
  
    // Style the phone input container
    :global(.PhoneInput) {
      width: 100%;
    }
  
    // Style the phone input field
    :global(.PhoneInputInput) {
      width: 100%;
      padding: 10px 12px;
      font-size: 12px;
      border: 1px solid #dfdedd;
      border-radius: 6px;
      background-color: #fdfdfc;
      color: black;
  
      &::placeholder {
        color: #acaba8;
      }
  
      &:hover {
        border-color: #143CFF;
      }
  
      &:focus {
        outline: none;
        border-color: #143CFF;
        box-shadow: 0 0 4px #143CFF;
      }
    }
  
    // Style the country select button
    :global(.PhoneInputCountry) {
      margin-right: 8px;
    }
  }
  
  .errorMessage {
    color: #ff0000;
    font-size: 12px;
    margin-top: 4px;
  }
  