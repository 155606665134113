@import "../.../../../../../styles/global.scss";

.roundLabelledCheckboxContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  height: 30px;

  >label {
    margin-left: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.roundCheckbox {
  position: relative;
  margin-right: 10px;
  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }
  
  label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
  
  input[type="checkbox"] {
    visibility: hidden;
  }
  
  input[type="checkbox"]:checked + label {
    background-color: $fm_blue;
    border-color: $fm_blue;
  }
  
  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}
